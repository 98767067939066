function Review(props) {
  const review = props.review;

  return (
    <div >
      <div className="reviewLink">
      <h4><a href={review.url}>{`${review.by}`}</a></h4>
      <p style={{
          fontSize: 'medium'
        }} >{`${review.text}`}</p>
    </div>
    </div>
  );
}

export default Review;
