import './styles/morvigor_styles.css';
import './styles/small_media.css';
import './styles/mobile.css';
import Header from './Header.js';
import About from './About.js';
import Gigs from './Gigs.js';
import Music from './Music.js';

import {Helmet} from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width,initial-scale=1"/>
        <title>Morvigor</title>
      </Helmet>
      <Header/>
      <div className="wrapper">
        <About/>
        <Gigs/>
        <Music/>
      </div>
    </>
  );
}

export default App;
