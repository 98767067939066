import banner from './images/banner.jpg';
import NavigationBar from './NavigationBar.js';

function Header() {
  return(
    <>
    <NavigationBar/>
      <img src={banner} className="backgroundIMG" alt=""/>
    </>
  );
};

export default Header;
