import Reviews from './Reviews.js';

const generateLinks = (album) => {
  const links = album.links || [];
  const validLinks = links.filter(link => link.url)
  return <div style={{marginBottom: 20, textAlign: "center"}}>
    {validLinks.map((link, index) => {
      return(
        
        <a
          rel="noreferrer"
          target="_blank"
          className="socialLinks"
          href={link.url}>
            <i>
              {index > 0 && ' / '}
              {link.label}
            </i>
        </a>
      )
    })}
  </div>
};

function Album({album}) {
  if(!album.show) return;
  const links = generateLinks(album);
  return(
    <div style={{ textAlign: 'left'}}>
      <h3 style={{display: 'inline'}}>{album.name}</h3> {album.year}
      {album.cover && <img src={album.cover} className='AlbumPhoto' alt=""/>}
      {album.links && links}
      {album.spotifyLink &&
        <div>
          <iframe
            title={album.name}
            src={album.spotifyLink}
            width="100%"
            height="90px"
            frameborder="0"
            allowtransparency="true">
          </iframe>
        </div>
      }
      {album.infoMain && album.infoMain.map((e) => {
        return <p className='AlbumInfoMain'>{e}</p>
      })}
      {album.infoDetails && album.infoDetails.map((e) => {
        return <p className='AlbumInfoDetails'>{e}</p>
      })}
      <div style={{marginBottom: '10%'}}></div>
      <Reviews album={album}/>
    </div>
  );
}

export default Album;
