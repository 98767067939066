import {parseDateToString} from './helpers/date';

function Gig(gig) {
  const parsedDate = parseDateToString(gig.date)
  return (
  <tr>
    <td>{ parsedDate }</td>
    <td>{ gig.venue }</td>
    <td>{ gig.city }</td>
    <td>{ gig.bands.join(', ') }</td>
  </tr>
  )

};

export default Gig;
