import React, {  useEffect, useState } from "react";
import './styles/morvigor_styles.css';
import Gig from './Gig.js';
import {sort, reverseDate, parseStringToDate, getGigsToShow} from './helpers/date.js';
import { getConfig } from "./helpers/config";

function Gigs() {
  const [gigsConfig, setGigsConfig] = useState([]);
  const [config, setConfig] = useState({});
  const [hide, setHide] = useState(false);

  useEffect(() => {
    const fetchConfig = async () => {
        const config = await getConfig('config.json');
        const gigs = await getConfig('gigs.json');
        setConfig(config);
        setGigsConfig(gigs);
    }
    fetchConfig();
  }, []);

  const handleOnClick = () => setHide(!hide);
  const hidePastGigs = config?.gigs?.hide_past_gigs;
  const reversed = reverseDate(gigsConfig);
  const parsed = parseStringToDate(reversed);
  const sorted = sort(parsed);
  const upcoming = getGigsToShow(sorted);
  const pastGigs = sorted.filter(gig => !upcoming.includes(gig));

  return (
    <div id="Gigs">
      <h2 style={{ marginBottom: "2%" }}> Gigs </h2>
      <hr />
      <table className="gigtable" styles={{ marginTop: "15%" }}>
        <tr font-weight="bold">
          <th >Date</th>
          <th >  Venue</th>
          <th >  City</th>
          <th >  Bands</th>
        </tr>
        {upcoming.map(g => {
          return Gig(g)
        })}
      </table>
      {!hidePastGigs &&
        <>
          <h3
            className="showMore"
            onClick={() => handleOnClick()}
          > show past gigs
          </h3>
          {!hide &&
            <>
              <hr />
              <table className="gigtable" styles={{ marginTop: "15%" }}>
                <tr font-weight="bold">
                  <th min-width="100px" width="0.5%"></th>
                  <th min-width="100px" width="1%"></th>
                  <th min-width="100px" width="1%"></th>
                  <th min-width="100px" width="3%"></th>
                </tr>
                {pastGigs.map(g => {
                  return Gig(g)
                })}
              </table>
            </>
          }
        </>
      }
    </div>
  );
};

export default Gigs;
