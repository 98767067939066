import React, { useEffect, useState } from "react";
import Album from './Album.js';
import { getConfig } from "./helpers/config";

const ALBUMS = {
  TYRANT: 'tyrant',
  SPIEGEL: 'spiegel'
}

function Music() {
  const [ albums, setAlbums ] = useState();

  useEffect(() => {
    const fetchConfig = async () => {
        const albums = await getConfig('albums.json');
        setAlbums(albums);
    }
    fetchConfig();
  }, []);

  if(!albums) return(<></>);

  const tyrant = albums[ALBUMS.TYRANT];
  const spiegel = albums[ALBUMS.SPIEGEL];

  return(
    <div id="Music">
        <h2> Albums </h2>
        <hr styles={{width: "100%"}}/>
        <div id="MusicContainer">

        <Album album={spiegel}/>
        <Album album={tyrant}/>
      </div>
    </div>
  )

};

export default Music;

